<div class="dt-main">
  <app-sidebar></app-sidebar>
  <div class="dt-content-wrapper overflow-y-scroll">
    <div class="dt-content">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <div class="left-section">
            <img
              src="assets/images/back-button.png"
              routerLink="/insights/energy-efficiency"
            />
            <h2 class="pageHeading heading">Explore Savings Potential</h2>
          </div>
          <button class="create-scan-btn" (click)="openCreateScanModal()">
            Create ECM Scan
          </button>
        </div>
        <div class="bg-lightgrey overflow-y-scroll"  *ngIf="!isLoading">
          <div class="row" >
            <div class="col-md-6 mb-4" *ngFor="let scan of ecmScans; let i = index">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <p>Timeperiod: {{scan.timeperiod}} days</p>
                  <p>Start Date: {{scan.start_date}}</p>
                  <p>End Date: {{scan.end_date}}</p>
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan" [queryParams]="{scan_id: scan.id, timeperiod: scan.timeperiod}">
                    Scan #{{i+1}}
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isLoading">
          <div class="loaderContainer">
            <div class="loader"></div>
          </div>
        </ng-container>
        <!-- Add Modal -->
        <div class="modal" *ngIf="showCreateModal">
          <div class="modal-content">
            <div class="modal-header">
              <h3>Create New ECM Scan</h3>
              <button class="close-btn" (click)="closeCreateScanModal()">&times;</button>
            </div>
            <div class="modal-body">
              <form [formGroup]="scanForm" (ngSubmit)="createScan()">
                <div class="form-group">
                  <label for="timeperiod">Time Period (days)</label>
                  <input 
                    type="number" 
                    id="timeperiod" 
                    formControlName="timeperiod"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="startDate">Start Date</label>
                  <input 
                    type="date" 
                    id="startDate" 
                    formControlName="startDate"
                    class="form-control"
                  >
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn-cancel" (click)="closeCreateScanModal()">Cancel</button>
                  <button type="submit" class="btn-save" [disabled]="!scanForm.valid || isSubmitting" (click)="createScan()">
                    {{ isSubmitting ? 'Creating...' : 'Create Scan' }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
