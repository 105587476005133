<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="dt-content dt-content-overwrite">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights/asset-intelligence"
          />
          <h2 class="pageHeading heading">{{circuitName}} - Cycle Explorer</h2>
        </div>

        <div class="bg-lightgrey card-container">
          <div class="card-content circuitUsageContainer  overflow-y-scroll">

            <div class="circuitUsageListContainer">
              <ng-container *ngIf="!isCyclesLoading && circuit_list.length > 0">
                <div style="color: black;">
                  <div>Cycle number</div>
                  <div>Energy Used</div>
                  <div>Amps Peak</div>
                </div>
                <div *ngFor="let circuitCycle of circuit_list">
                  <div (click)="circuitCycleOnClick(circuitCycle.cycle_number)" style="cursor: pointer;" > 
                    <!-- [ngStyle]="{'background-color': selectedCycleNumber === circuitCycle.cycle_number ? '#e6f3ff' : 'transparent'}" -->
                    <div>#{{ circuitCycle.cycle_number }}</div>
                  </div>
                  <div id="{{ circuitCycle.cycle_number }}">
                    <div>{{ circuitCycle.total_power }}kWh</div>
                  </div >
                  <div id="{{ circuitCycle.cycle_number }}">
                    <div>{{ circuitCycle.max_current }}A</div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!isCyclesLoading && circuit_list.length === 0" >
                  <p class="c">No data available for the selected date range</p>
              </ng-container>

              <ng-container *ngIf="isCyclesLoading">
                <div class="loaderContainer">
                  <div class="loader"></div>
                </div>
              </ng-container>
            </div>
          </div>

          <div
            class="card-content secondaryCard"
            style="background-color: #fff"
          >
            <div class="dateSectionContainer">
              <div class="cycleSelected">
                #{{ selectedCycleNumber }}
              </div>

              <div class="dateContainer">
                <div class="dateRangeSelector">
                  <!-- <div
                    id="rangeSelectorDay"
                    (click)="rangeSelected($event)"
                    [attr.data-selected]="rangeSelectedDay === true"
                  >
                    Day
                  </div> -->

                  <div
                    id="rangeSelectorMonth"
                    (click)="rangeSelected($event)"
                    [attr.data-selected]="rangeSelectedMonth === true"
                  >
                    Month
                  </div>
                </div>

                <div class="dateActionContainer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="20"
                    style="fill: white; transform: scale(0.6)"
                    (click)="selectPrevious()"
                  >
                    <path d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z" />
                  </svg>

                  <div class="formattedDate">
                    {{ formattedDate | date : dateFormat }}
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="20"
                    style="fill: white; transform: scale(0.6) rotate(180deg)"
                    (click)="selectNext()"
                  >
                    <path d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z" />
                  </svg>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!isLoading">
              <div class="infoContainer">
                <div>
                  <div class="infoContentHeader">Circuit Visualization</div>
                </div>
              </div>
              <!-- <div class="chartdv">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [labels]="chartOptions.labels"
                  [responsive]="chartOptions.responsive"
                  [legend]="chartOptions.legend"
                  [dataLabels]="chartOptions.dataLabels"
                  [colors]="chartOptions.colors"
                >
                  ></apx-chart
                >
              </div> -->
              <div
              class="line-usage-chart"
                  echarts
                  [options]="lineChartOptions"
                  (chartInit)="onChartReady($event)"
                >
              </div>

              <div class="infoContainer">
                <div>
                  <div class="infoContentHeader">Cycle Breakdown</div>
                </div>
              </div>

              <div class="infoContainer">
                <div>
                  <div class="infoContentHeader">Time-on:</div>
                  <div class="infoContentHeaderValue">{{ selectedCycle?.start_time | date : cycleDateFormat }}</div>
                </div>
                <div>
                  <div class="infoContentHeader">Amp Peak:</div>
                  <div class="infoContentHeaderValue">{{selectedCycle?.max_current ?? 'N/A'}} A</div>
                </div>
                <div>
                  <div class="infoContentHeader">Time-off:</div>
                  <div class="infoContentHeaderValue">{{ selectedCycle && selectedCycle.end_time ? (selectedCycle.end_time | date : cycleDateFormat) : 'N/A' }}</div>
                </div>
                <div>
                  <div class="infoContentHeader">Total Energy Used:</div>
                  <div class="infoContentHeaderValue">{{selectedCycle?.total_power ?? 'N/A'}} kWh</div>
                </div>
                <div>
                  <div class="infoContentHeader">Total run time:</div>
                  <div class="infoContentHeaderValue">{{selectedCycle?.run_time ?? 'N/A'}}</div>
                </div>
                <div>
                  <div class="infoContentHeader">Outdoor temp:</div>
                  <div class="infoContentHeaderValue">N/A</div>
                </div>
              </div>

              <!-- <div
                class="infoContainer"
                style="flex: 1"
              >
                <div
                  class="bill-breakdown-pie currentPie"
                  style="height: 100%; display: grid; place-content: center"
                >
                  <div class="byfees">
                    <div class="chartdv">
                      <apx-chart
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [labels]="chartOptions.labels"
                        [responsive]="chartOptions.responsive"
                        [legend]="chartOptions.legend"
                        [dataLabels]="chartOptions.dataLabels"
                        [colors]="chartOptions.colors"
                        [stroke]="chartOptions.stroke"
                      ></apx-chart>
                      <span class="chartper">100%</span>
                    </div>
                  </div>
                </div>

                <div
                  style="
                    display: grid;
                    gap: 12px;
                    grid-template-rows: auto 1fr;
                    height: 100%;
                    align-items: start;
                  "
                >
                  <div class="sidetabOptionsHeaders">
                    <div
                      *ngFor="let option of sidetabOptions; let i = index"
                      (click)="handleSidetabOptionsChange(i)"
                      [attr.data-selected]="selectedSidetabOptions === i"
                    >
                      {{ option.label }}
                    </div>
                  </div>

                  <div class="sidetabOptionsContainer">
                    <div class="sidetabOptionsLabel">
                      {{ sidetabOptions[selectedSidetabOptions].label }}
                    </div>

                    <div class="sidetabOptions">
                      <div
                        *ngFor="
                          let option of sidetabOptions[
                            selectedSidetabOptions
                          ].getOptions()
                        "
                      >
                        <div class="assetsInfo">
                          <svg
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.583984 6.49967C0.583984 2.95585 3.45682 0.0830078 7.00065 0.0830078C10.5444 0.0830078 13.4173 2.95584 13.4173 6.49967C13.4173 10.0435 10.5445 12.9163 7.00065 12.9163C3.45682 12.9163 0.583984 10.0435 0.583984 6.49967ZM7.00065 1.24967C4.10115 1.24967 1.75065 3.60018 1.75065 6.49967C1.75065 9.39914 4.10116 11.7497 7.00065 11.7497C9.90012 11.7497 12.2507 9.39914 12.2507 6.49967C12.2507 3.60018 9.90012 1.24967 7.00065 1.24967ZM10.3298 4.04553C10.5576 4.27333 10.5576 4.64268 10.3298 4.87049L6.24646 8.95382C6.01866 9.18163 5.64931 9.18163 5.42151 8.95382L3.67151 7.20382C3.4437 6.97601 3.4437 6.60667 3.67151 6.37886C3.89931 6.15106 4.26866 6.15106 4.49646 6.37886L5.83398 7.71638L9.50484 4.04553C9.73265 3.81772 10.102 3.81772 10.3298 4.04553Z"
                              [ngStyle]="{ fill: option[1].color }"
                            />
                          </svg>

                          <div>{{ option[0] }}</div>
                        </div>

                        <div>{{ toPercentage(option[1].value) }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </ng-container>

            <ng-container *ngIf="isLoading">
              <div class="loaderContainer">
                <div class="loader"></div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
