import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { customToFixed } from 'src/app/helpers/circuit';
import { ChartOptions } from 'src/app/live-usage/live-usage.component';
import { Building } from 'src/app/models/building';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';
import { MatDialog } from '@angular/material/dialog';
import { CircuitEcmInfoComponent } from '../circuit-ecm-info/circuit-ecm-info.component';
import { ActivatedRoute } from '@angular/router';

interface CircuitECMSelection {
  id: number;
  ecm: {
    id: number;
    name: string;
    description: string;
  };
  circuit: {
    circuit_name: string;
    id: number;
    is_monitored: boolean;
  };
  status: string;
  savings: {
    [key: number]: {
      mean: number;
      end_date: string;
      max_saving: number;
      min_saving: number;
      start_date: string;
      ecm_scan_id: number;
      total_saving: number;
      min_saving_formatted: string;
      max_saving_formatted: string;
    }
  };
  created_at: string;
  updated_at: string;
}

interface RecordKeyVal {
  value: number;
  color: string;
}

@Component({
  selector: 'app-savings-scan',
  templateUrl: './savings-scan.component.html',
  styleUrls: ['./savings-scan.component.scss']
})
export class SavingsScanComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { initialize: false };

  public scan_id: number;
  public timeperiod: number;



  public modes = [
    {
      id: 'energy',
      label: 'Energy',
      getValue: (d: number) => `${this.toKwH(d, 3)} kWh`
    },
    {
      id: 'cost',
      label: 'Cost',
      getValue: (d: number) => `$${this.toCost(d, 2)}`
    }
  ];

  public DEMONINATOR = 3600000;

  public tabOptions = [
    {
      id: 'suggested-ecm',
      label: 'Suggested ECMs',
      color: '#1FAB3D',
      header: 'ECM\'s Detected',
    },
    {
      id: 'rejected-ecm',
      label: 'Rejected ECMs',
      color: '#E04A41',
      header: 'ECM\'s Rejected',
    },
  ];

  public sortOptions = [
    {
      id: 'savings',
      label: 'Savings',
      selector: (d: CircuitECMSelection) => d.savings[this.timeperiod].max_saving ?? 0,
      getTotal: () => this.total_accepted_max_savings,
      min_savings: (d: CircuitECMSelection) => d.savings[this.timeperiod].min_saving ?? 0,
    },
  ];

  public selectedTab = 0; 

  public selectedMode = 0;
  public selectedSort = 0;


  public total_usage = 0;
  public maxUsage = 0;
  public minUsage = 0;

  public total_circuit_total_on_hours_usage = 0;
  public total_on_hours_usage = 0;

  public suggestedECMsList: CircuitECMSelection[] = [];
  public rejectedECMsList: CircuitECMSelection[] = [];
  public total_percentage = 0;

  public rangeSelectedDay = true;
  public rangeSelectedWeek = false;
  public rangeSelectedMonth = false;
  public intervalSelected = '60m';

  public selectedMonth: Date = new Date();
  public selectedDay: Date = new Date();

  public customToFixed = customToFixed;

  private building: Building;
  public operatingHours: any = [];

  public assets: [string, RecordKeyVal][] = [];
  public panels: [string, RecordKeyVal][] = [];

  public sidetabOptions = [
    { id: 'assets', label: 'Assets', getOptions: () => this.assets },
    { id: 'panel', label: 'Panel', getOptions: () => this.panels }
  ];

  public selectedSidetabOptions = 0;
  public isLoading = true;
  public total_accepted_min_savings = 0;
  public total_accepted_max_savings = 0;
  public total_accepted_min_savings_formatted = '0';
  public total_accepted_max_savings_formatted = '0';

  public total_rejected_min_savings = 0;
  public total_rejected_max_savings = 0;
  public total_rejected_min_savings_formatted = '0';
  public total_rejected_max_savings_formatted = '0';

  constructor(private dashboardService: DashboardDataService,public dialog: MatDialog, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      this.scan_id = params['scan_id'];
      this.timeperiod = params['timeperiod'];
    });

    const subs = this.dashboardService.currentBuilding.subscribe(result => {
      if (!result) return;

      this.building = result;

      this.getAll();

      subs.unsubscribe();
    });
  }

  getCircuitUsageList() {
    this.isLoading = true;

    const subs = this.dashboardService
      .getEcms(this.building.idbuildings)
      .subscribe((result: CircuitECMSelection[]) => {

        this.total_accepted_min_savings = 0;
        this.total_accepted_max_savings = 0;
        this.total_rejected_min_savings = 0;
        this.total_rejected_max_savings = 0;

        this.suggestedECMsList = [];
        this.rejectedECMsList = [];

        result.forEach(row => {
          if (row.status === 'unreviewed' || row.status === 'accepted') {
            this.suggestedECMsList.push(row);
            const d = row.savings[this.timeperiod];
            if (d.min_saving) {
              const minSavings = typeof d.min_saving === 'string' ? parseFloat(d.min_saving) : d.min_saving;
              this.total_accepted_min_savings += minSavings;
              d.min_saving_formatted = this.toKwH(minSavings, 3);
            }
            if (d.max_saving) {
              const maxSavings = typeof d.max_saving === 'string' ? parseFloat(d.max_saving) : d.max_saving;
              this.total_accepted_max_savings += maxSavings;
              d.max_saving_formatted = this.toKwH(maxSavings, 3);
            }
          } else if (row.status === 'rejected') {
            this.rejectedECMsList.push(row);
            const d = row.savings[this.timeperiod];
            if (d.min_saving) {
              const minSavings = typeof d.min_saving === 'string' ? parseFloat(d.min_saving) : d.min_saving;
              this.total_rejected_min_savings += minSavings;
              d.min_saving_formatted = this.toKwH(minSavings, 3);
            }
            if (d.max_saving) {
              const maxSavings = typeof d.max_saving === 'string' ? parseFloat(d.max_saving) : d.max_saving;
              this.total_rejected_max_savings += maxSavings;
              d.max_saving_formatted = this.toKwH(maxSavings, 3);
            }
          }
        }); 
        this.total_accepted_min_savings_formatted = this.toKwH(this.total_accepted_min_savings, 3);
        this.total_accepted_max_savings_formatted = this.toKwH(this.total_accepted_max_savings, 3);

        this.total_rejected_min_savings_formatted = this.toKwH(this.total_rejected_min_savings, 3);
        this.total_rejected_max_savings_formatted = this.toKwH(this.total_rejected_max_savings, 3);

        this.getSortedCircuitList();
        this.isLoading = false;
        subs.unsubscribe();
      });
  }

  getFormattedDate(date: Date) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  }

  getSortedCircuitList() {
    const { selector } = this.sortOptions[this.selectedSort];
    const list = structuredClone(this.suggestedECMsList);
    list.sort((a, b) => selector(b) - selector(a));
    this.suggestedECMsList = list;

    const list2 = structuredClone(this.rejectedECMsList);
    list2.sort((a, b) => selector(b) - selector(a));
    this.rejectedECMsList = list2;

    this.getChartsOptions();
  }

  toKwH(value: number, fixed = 1) {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: fixed
    }).format(value / this.DEMONINATOR);
  }

  toCost(value: number, fixed = 1, cost=0.192) {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: fixed
    }).format(value*cost / this.DEMONINATOR);
  }

  toPercentage(value: number) {
    return (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
  }
  toCircuitPercentage(circuitUsage: CircuitECMSelection) {
    let value = circuitUsage.savings[this.timeperiod].max_saving;
    let x= (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
    console.log(x);
    return x;
  }
  toUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }

  get formattedDate(): Date {
    return this.rangeSelectedDay ? this.selectedDay : this.selectedMonth;
  }

  get dateFormat(): string {
    return this.rangeSelectedDay ? 'dd MMM yyyy' : 'LLLL YYYY';
  }

  handleTabChange(i: number) {
    if (this.selectedTab !== i) {
      this.selectedTab = i;
      this.getSortedCircuitList();
    }
  }

  getDateSlice(date: string | undefined, index: number) {
    return Number(date?.split(':')[index] ?? '');
  }

  getAmOrPm(date: string) {
    return this.getDateSlice(date, 0) < 12 ? 'AM' : 'PM';
  }

  getChartsOptions() {
      const options = [
        {
          name: 'Suggested ECMs',
          value: this.suggestedECMsList.length,
          color: '#1FAB3D'
        },
        {
          name: 'Rejected ECMs',
          value: this.rejectedECMsList.length,
          color: '#E04A41'
        }
      ]

    this.chartOptions = {
      series: options.map(d => d.value),
      dataLabels: { enabled: false },
      legend: { show: false },
      chart: { type: 'donut', width: '100%' },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: '',
                formatter: () => 'Text you want'
              }
            }
          }
        }
      },
      colors: options.map(d => d.color),
      labels: options.map(d => d.name),
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: 10 },
            legend: { position: 'bottom' }
          }
        }
      ],
      initialize: true,
      stroke: { show: false }
    };
  }

  getAll() {
    this.getCircuitUsageList();
  }

  handleSidetabOptionsChange(i: number) {
    if (i !== this.selectedSidetabOptions) {
      this.selectedSidetabOptions = i;
      this.getChartsOptions();
    }
  }

  openCircuitModal(circuit: CircuitECMSelection) {
    console.log(circuit);
    const dialogRef = this.dialog.open(CircuitEcmInfoComponent, {
      data: {
        circuitEcmSelection: circuit,
        timeperiod: this.timeperiod
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  ecmAction(circuit: CircuitECMSelection, action: number) {
    const actionMap = {
      0: 'rejected',
      1: 'accepted',
      2: 'unreviewed'
    } as const;
    this.dashboardService.updateCircuitEcmStatus(circuit.circuit.id, circuit.ecm.id, actionMap[action as keyof typeof actionMap]).subscribe(result => {
      if (result == null) {
        if (action === 0) {
          this.rejectedECMsList.push(circuit);
          this.suggestedECMsList = this.suggestedECMsList.filter(c => c.circuit.id !== circuit.circuit.id);
        } else if (action === 1) {
          this.suggestedECMsList.push(circuit);
          this.rejectedECMsList = this.rejectedECMsList.filter(c => c.circuit.id !== circuit.circuit.id);
        }

        this.getSortedCircuitList();
      }
    });
  }

  ngOnInit(): void {}
}

