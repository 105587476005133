import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartComponent } from 'ng-apexcharts';
import { customToFixed } from 'src/app/helpers/circuit';
import { ChartOptions } from 'src/app/live-usage/live-usage.component';
import { Building } from 'src/app/models/building';
import { EcmScan } from 'src/app/models/ecm_scan';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';

@Component({
  selector: 'app-explore-savings-potential',
  templateUrl: './explore-savings-potential.component.html',
  styleUrls: ['./explore-savings-potential.component.scss']
})
export class ExploreSavingsPotentialComponent {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { initialize: false };

  public building: Building;
  public isLoading = true;
  public ecmScans: EcmScan[] = [];
  public showCreateModal = false;
  public scanForm: FormGroup;
  public isSubmitting = false;

  constructor(
    private dashboardService: DashboardDataService,
    private fb: FormBuilder
  ) {
    this.initForm();
    const subs = this.dashboardService.currentBuilding.subscribe(result => {
      if (!result) return;
      this.building = result;
      subs.unsubscribe();
    });
    this.getEcmScans();
  }

  private initForm() {
    this.scanForm = this.fb.group({
      timeperiod: ['', [Validators.required, Validators.min(1)]],
      startDate: ['', Validators.required]
    });
  }

  openCreateScanModal() {
    this.showCreateModal = true;
  }

  closeCreateScanModal() {
    this.showCreateModal = false;
    this.scanForm.reset();
  }

  createScan() {
    if (this.scanForm.invalid || this.isSubmitting) return;

    this.isSubmitting = true;
    const scanData = {
      timeperiod: this.scanForm.value.timeperiod,
      start_date: this.scanForm.value.startDate,
      building: this.building.idbuildings
    };

    this.dashboardService.createEcmScan(scanData).subscribe({
      next: (response) => {
        this.closeCreateScanModal();
        this.getEcmScans(); // Refresh the list
      },
      error: (error) => {
        console.error('Error creating ECM scan:', error);
        // Handle error - maybe show a message to user
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  }

  getEcmScans() {
    this.dashboardService.getEcmScans(this.building.idbuildings).subscribe(result => {
      this.ecmScans = result;
      this.isLoading = false;
    });
  }
}
