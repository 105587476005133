<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="dt-content dt-content-overwrite">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights/energy-efficiency/explore-energy-potential"
          />
          <h2 class="pageHeading heading">ECM SCAN #1</h2>
        </div>

        <div class="bg-lightgrey overflow-y-scroll card-container">
          <div
            class="card-content secondaryCard"
            style="background-color: #fff"
          >

            <ng-container *ngIf="!isLoading">
              <div class="sortByContainer">
                <div class="sortByHeader"></div>

                <div class="sortByActions">
                  <ng-container
                    *ngFor="let option of tabOptions; let i = index"
                  >
                    <div
                    [ngStyle]="{
                      'color': option.color
                    }"
                      (click)="handleTabChange(i)"
                      [attr.date-selected]="selectedTab == i"
                    >
                      {{ option.label }}
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="infoContainer">
                <h3>{{tabOptions[selectedTab].header}}</h3>
              </div>
              <!-- #1FAB3D -->
              <div 
              [ngStyle]="{
                'color': tabOptions[selectedTab].color
              }"
              class="ecm-metrics">
                <div
                  class="bill-breakdown-pie currentPie"
                  style="height: 100%; display: grid; place-content: center"
                >
                  <div class="byfees">
                    <div class="chartdv">
                      <apx-chart
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [labels]="chartOptions.labels"
                        [responsive]="chartOptions.responsive"
                        [legend]="chartOptions.legend"
                        [dataLabels]="chartOptions.dataLabels"
                        [colors]="chartOptions.colors"
                        [stroke]="chartOptions.stroke"
                      ></apx-chart>
                      <span class="chartper">100%</span>
                    </div>
                  </div>
                  <div class="ecm-metrics-info">
                    <br>
                    <h3> {{selectedTab==0 ? suggestedECMsList.length : rejectedECMsList.length}}</h3>
                    <p>Energy Savings Oppurtunities</p>
                    <br>
                    <p>Est. Annual<br>Savings (kWh)</p>
                    <svg 
                    *ngIf="selectedTab==0"
                     width="264" height="16" viewBox="0 0 264 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H85.5V16H8.5C4.08172 16 0.5 12.4183 0.5 8Z" fill="#BFF2CE"/>
                      <rect x="89.5" width="85" height="16" fill="#47DC74"/>
                      <path d="M178.5 0H255.5C259.918 0 263.5 3.58172 263.5 8C263.5 12.4183 259.918 16 255.5 16H178.5V0Z" fill="#1FAB3D"/>
                      </svg>
                      <svg *ngIf="selectedTab==1"
                      width="264" height="16" viewBox="0 0 264 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H85.5V16H8.5C4.08172 16 0.5 12.4183 0.5 8Z"
                          ng-reflect-ng-style="[object Object]" style="fill: rgb(205, 135, 132);"></path>
                        <rect x="89.5" width="85" height="16" ng-reflect-ng-style="[object Object]"
                          style="fill: rgb(196, 84, 78);"></rect>
                        <path _ngcontent-boe-c493=""
                          d="M178.5 0H255.5C259.918 0 263.5 3.58172 263.5 8C263.5 12.4183 259.918 16 255.5 16H178.5V0Z"
                          ng-reflect-ng-style="[object Object]" style="fill: rgb(236, 27, 16);"></path>
                      </svg>
                    <div class="ecm-metrics-range">
                      <p>{{selectedTab==0 ? total_accepted_min_savings_formatted : total_rejected_min_savings_formatted}}</p>
                      <p>{{selectedTab==0 ? total_accepted_max_savings_formatted : total_rejected_max_savings_formatted}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="isLoading">
              <div class="loaderContainer">
                <div class="loader"></div>
              </div>
            </ng-container>
          </div>
          <div class="card-content circuitUsageContainer">
            <div class="circuitUsageController">
              <ng-container *ngFor="let mode of modes; let i = index">
                <label
                  (click)="selectedMode = i"
                  class="radioInput"
                >
                  <input
                    type="radio"
                    [checked]="i === selectedMode"
                  />

                  <span>{{ mode.label }}</span>
                </label>
              </ng-container>
            </div>

            <div class="ecmUsageListContainer">
              <ng-container *ngIf="!isLoading">
                <div *ngFor="let circuitUsage of (selectedTab==0 ? suggestedECMsList : rejectedECMsList) ; let i = index">
                    <div class="ecmInfo">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 0C3.14035 0 0 3.14035 0 7C0 10.8596 3.14035 14 7 14C10.8596 14 14 10.8596 14 7C14 3.14035 10.8596 0 7 0ZM10.9123 5.15789L6.4386 9.59649C6.17544 9.85965 5.75439 9.87719 5.47368 9.61403L3.10526 7.45614C2.82456 7.19298 2.80702 6.75439 3.05263 6.47368C3.31579 6.19298 3.75439 6.17544 4.03509 6.4386L5.91228 8.1579L9.91228 4.15789C10.193 3.87719 10.6316 3.87719 10.9123 4.15789C11.193 4.4386 11.193 4.87719 10.9123 5.15789Z" fill="#1FAB3D"/>
                        </svg>
                    </div>
                    <div (click)="openCircuitModal(circuitUsage)" class="ecmInfo">
                      <div class="ecmNumber">ECM #{{ i+1 }}</div>
                      <div>{{ circuitUsage.circuit.circuit_name }}</div>
                    </div>
  
                    <div (click)="openCircuitModal(circuitUsage)" class="ecmInfo">
                      <div
                      class="ecmWidth"
                      [ngStyle]="{
                        'width.px': toCircuitPercentage(
                          circuitUsage
                        ),
                        'min-width.px': 1
                      }"
                    ></div>
  
                      <div>
                        {{modes[selectedMode].getValue(sortOptions[selectedSort].min_savings(circuitUsage))}}
                        -
                        {{
                          modes[selectedMode].getValue(
                            sortOptions[selectedSort].selector(circuitUsage)
                          ) 
                        }}
                      </div>
                    </div>
                    <div *ngIf="selectedTab === 0" class="ecmInfo" (click)="ecmAction(circuitUsage, 0)">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#E04A41"/>
                      </svg>
                    </div>
                    <div class="ecmInfo" *ngIf="selectedTab === 1" (click)="ecmAction(circuitUsage, 1)">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                        <path d="M5 8.586L2.707 6.293L1.293 7.707L5 11.414L13.707 2.707L12.293 1.293L5 8.586Z" fill="#1FAB3D"/>
                      </svg>
                    </div>

                </div>
              </ng-container>

              <ng-container *ngIf="isLoading">
                <div class="loaderContainer">
                  <div class="loader"></div>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
