import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardDataService } from '../services/dashboard-data/dashboard-data.service';
import { Building } from '../models/building';
import { MatDialog } from '@angular/material/dialog';
import { Panel } from '../models/panel';
import { Circuit } from '../models/circuit';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
  ChartComponent
} from 'ng-apexcharts';
import { AuthService } from '../services/auth.service';
import { PanelMeterComponentComponent } from '../live-usage/panel-meter-component/panel-meter-component.component';
import { PanelInfoComponentComponent } from '../live-usage/panel-info-component/panel-info-component.component';
import { CircuitCategoryInfoComponent } from './circuit-category-info-component/circuit-category-info-component.component';
import { CircuitInfoComponent } from './circuit-info-component/circuit-info-component.component';
import { PanelMeterCreateComponent } from './panel-meter-create-component/panel-meter-create-component.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { customToFixed, groupCircuitsByCircuitGroup } from '../helpers/circuit';

import mqtt from 'mqtt';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

interface PanelCurrentResult {
  ID: string;
  data: Record<string, number>;
  time: number;
  type: 'main' | 'ext1' | 'ext2' | 'ext3';
}

type PanelCurrentData = Record<
  PanelCurrentResult['type'],
  {
    expansion_id: PanelCurrentResult['type'];
    meter_name: string;
    data: object;
    raw_data: object;
  }
>;

@Component({
  selector: 'app-live-usage',
  templateUrl: './live-usage.component.html',
  styleUrls: ['./live-usage.component.scss']
})
export class LiveUsageComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsPanel: Partial<ChartOptions> | any;
  buildings: Building[];
  currentBuilding: Building;
  public fetchLiveAPI: boolean = true;

  constructor(
    private dashboardDataService: DashboardDataService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this.chartOptions = {
      initialize: false
    };
    this.chartOptionsPanel = {
      initialize: false
    };
    this.dashboardDataService.watchBuildings().forEach(buildings => {
      if (buildings != null) {
        this.buildings = buildings;
      }
    });
    this.dashboardDataService
      .watchCurrentBuilding()
      .forEach((building: Building) => {
        if (building != null && this.currentBuilding == null) {
          this.currentBuilding = building;
          this.getPanels(building.idbuildings);
          this.getCircuitCategory(building.idbuildings);
        }
      });
  }

  public panels: Panel[] = [];
  public secondaryPanelSet = new Set<string>();

  circuitCategoryMain: any[];
  circuitGroupMain: any[];
  circuits: Circuit[];
  modal: string | null = null;
  stack: any[] = [];

  private panelsSubject: BehaviorSubject<Panel[]> = new BehaviorSubject<
    Panel[]
  >([]);
  panels$: Observable<Panel[]> = this.panelsSubject.asObservable();

  private circuitCatergoryMainSubject: BehaviorSubject<Circuit[]> =
    new BehaviorSubject<Circuit[]>([]);
  circuitCategoryMain$: Observable<Circuit[]> =
    this.circuitCatergoryMainSubject.asObservable();

  active = 2;
  public isCostSelected = false;
  public isPanelCollapsed: boolean[] = [];
  public isInventryCollapsed: boolean[] = [];
  public panelsIsConnected = true;
  public isInstalledUser = false;
  public totalPanelPower = 0;
  public totalCurrent: number = 0;
  public totalPower: number = 0;
  public totalCost: number = 0;
  public fetchsetInterval: boolean = false;
  private fetchLastMeterDataPanel: null | Panel = null;
  public currentSelection: boolean = true;
  public energySelection: boolean = false;
  public costSelection: boolean = false;

  public totalAssetCurrent: number = 0;
  public totalAssetPower: number = 0;
  public totalAssetCost: number = 0;

  public circuitCurrentMapping: { [key: number]: Circuit } = {};
  public circuitPowerMapping: { [key: string]: number } = {};
  public circuitCostMapping: { [key: string]: number } = {};

  public circuitCategoryCurrentMapping: { [key: number]: number } = {};
  public circuitCategoryPowerMapping: { [key: number]: number } = {};
  public circuitCategoryCostMapping: { [key: number]: number } = {};

  public sidebarCategoryActive: boolean = false;
  public sidebarPanelActive: boolean = true;

  public costPerKwh: number = 0.12;

  public combinePhases: boolean = false;

  public customToFixed = customToFixed;
  private mqttClient: mqtt.MqttClient | null = null;

  private panelCurrent: Record<string, PanelCurrentData> = {};
  private timeout: NodeJS.Timeout | null = null;

  ngOnInit(): void {
    // Show setting Icon for INSTALLER USERS
    if (this.authService.getSession('user_role') == 'INSTALLER_USER') {
      this.isInstalledUser = true;
    }
  }

  ngOnDestroy(): void {
    this.fetchLiveAPI = false;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    if (this.mqttClient) {
      this.mqttClient.end();
      this.mqttClient = null;
    }
  }

  openReportModal(panel: Panel) {
    (
      document.getElementById(panel.panel_name) as HTMLAnchorElement
    ).style.pointerEvents = 'none';
    let dialogRef = this.dialog.open(PanelMeterComponentComponent, {
      data: {
        panel: panel,
        fetch_live_meter: true,
        panels: this.panels
      }
    });
    this.fetchLiveAPI = false;
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.meterNameIsChanged) {
        panel.meter_name = result.meterName;
      }
      this.fetchLiveAPI = true;
      this.fetchsetInterval = true;
      (
        document.getElementById(panel.panel_name) as HTMLAnchorElement
      ).style.pointerEvents = '';
    });
  }

  openInfoModal(panel: any) {
    (
      document.getElementById(panel.panel_name) as HTMLAnchorElement
    ).style.pointerEvents = 'none';

    this.fetchLastMeterDataPanel = panel;

    const dialogRef = this.dialog.open(PanelInfoComponentComponent, {
      data: {
        panel: panel,
        fetch_live_meter: true,
        panels$: this.panels$,
        customToFixed: customToFixed,
        combinePhases: this.combinePhases
      }
    });

    this.getLastMeterData();

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPanels(this.currentBuilding.idbuildings);
        this.getCircuitCategory('');
      }

      this.fetchsetInterval = true;

      (
        document.getElementById(panel.panel_name) as HTMLAnchorElement
      ).style.pointerEvents = '';
    });
  }

  openCircuitCategoryModal(circuit: any) {
    (
      document.getElementById(circuit.name) as HTMLAnchorElement
    ).style.pointerEvents = 'none';

    const dialogRef = this.dialog.open(CircuitCategoryInfoComponent, {
      data: {
        circuit: circuit,
        fetch_live_meter: true,
        circuitCategoryMain$: this.circuitCategoryMain$,
        customToFixed: customToFixed
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPanels(this.currentBuilding.idbuildings);
        this.getCircuitCategory('');
      }

      this.fetchLiveAPI = true;
      this.fetchsetInterval = true;

      (
        document.getElementById(circuit.name) as HTMLAnchorElement
      ).style.pointerEvents = '';
    });
  }

  openCircuitModal(circuit: Circuit) {
    // (document.getElementById(circuit.circuit_name) as HTMLAnchorElement).style.pointerEvents = 'none'
    let groupedCircuits: any = [];
    let panel: Panel;

    if (circuit.panel_id) {
      panel = this.panels.filter(d => d.panel_id === circuit.panel_id)[0];
    } else {
      panel = this.panels.filter(d =>
        d.panel_circuits.find(
          e => e.id === circuit.id && e.panel_id === d.panel_id
        )
      )[0];
    }

    const { meter_name } = panel;

    if (this.combinePhases) {
      groupedCircuits = panel.panel_circuits.filter(
        (ele: any) => ele.circuit_group_temp == circuit.circuit_group_temp
      );
    } else {
      groupedCircuits = panel.panel_circuits.filter(
        (ele: any) => ele.circuit_name == circuit.circuit_name
      );
    }

    const copiedCircuit = structuredClone(circuit);

    copiedCircuit.circuit_name = this.combinePhases
      ? circuit.circuit_group_temp
      : circuit.circuit_name;

    this.fetchLastMeterDataPanel = panel;

    const dialogRef = this.dialog.open(CircuitInfoComponent, {
      data: {
        circuit: copiedCircuit,
        meter_name: meter_name,
        groupedCircuits: groupedCircuits,
        panel_name: panel.panel_name,
        buildingId: this.currentBuilding.idbuildings,
        circuitCategories: this.circuitCategoryMain,
        panel: panel,
        panels$: this.panels$,
        customToFixed: customToFixed,
        combinePhases: this.combinePhases
      }
    });

    this.getLastMeterData();

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPanels(this.currentBuilding.idbuildings);
        this.getCircuitCategory('');
      }

      this.fetchsetInterval = true;

      (
        document.getElementById(circuit.circuit_name) as HTMLAnchorElement
      ).style.pointerEvents = '';
    });
  }

  closePopup(name: any) {
    let modalValue: any = document.getElementById(name);
    modalValue.style.display = 'none';
  }

  getPanels(buildingId: any): void {
    this.dashboardDataService
      .getPanels(buildingId)
      .forEach((panels: any) => {
        this.panels = panels.results;

        this.panels.forEach(d => {
          d.secondary_panel && this.secondaryPanelSet.add(d.secondary_panel);

          d.panel_circuits = [];
          d.panel_circuitsGrouped = [];
          d.panel_circuitsIndexed = {};
        });

        this.panelsSubject.next(this.panels);
        this.dashboardDataService.getCurrentBuilding();
      })
      .then(() => {
        this.getCircuits();
      });
  }

  getCircuitCategory(buildingId: any): void {
    this.dashboardDataService
      .getCircuitCategory(buildingId)
      .forEach((circuitCategory: any) => {
        this.circuitCategoryMain = JSON.parse(
          JSON.stringify(circuitCategory.results)
        );
        let totalCurrentCategory = 0;
        let totalPowerCategory = 0;
        let totalCostCategory = 0;
        for (const circuitCategory of this.circuitCategoryMain) {
          this.isInventryCollapsed.push(true);
          circuitCategory.circuit_setGroup = groupCircuitsByCircuitGroup(
            circuitCategory.circuit_set
          );
          for (const element of circuitCategory.circuit_set) {
            element.circuit_group_temp =
              element.circuit_group == null || element.circuit_group == ''
                ? element.circuit_name
                : element.circuit_group;
            if (this.circuitCurrentMapping[element.id]) {
              element['connected'] =
                this.circuitCurrentMapping[element.id].connected;
              element['circuit_disabled'] =
                this.circuitCurrentMapping[element.id].circuit_disabled;
              element['circuit_current'] =
                this.circuitCurrentMapping[element.id].circuit_current;
              element['circuit_power'] =
                this.circuitCurrentMapping[element.id].circuit_power;
              element['circuit_cost'] =
                this.circuitCurrentMapping[element.id].circuit_cost;
              totalCurrentCategory =
                totalCurrentCategory +
                this.circuitCurrentMapping[element.id].circuit_current;
              totalPowerCategory =
                totalPowerCategory +
                this.circuitCurrentMapping[element.id].circuit_power;
              totalCostCategory =
                totalCostCategory +
                this.circuitCurrentMapping[element.id].circuit_cost;
            }
          }
          this.circuitCategoryCurrentMapping[circuitCategory.id] =
            totalCurrentCategory;
          this.circuitCategoryPowerMapping[circuitCategory.id] =
            totalPowerCategory;
          this.circuitCategoryCostMapping[circuitCategory.id] =
            totalCostCategory;
        }
      });
  }

  getPanelMeterVoltage(
    voltage_ref: 'U1' | 'U2' | 'U3',
    element: Panel,
    result: PanelCurrentData
  ) {
    const { voltage_refrence_type, panel_meter } = element;

    if (
      panel_meter &&
      voltage_refrence_type &&
      voltage_ref.replace('U', 'V') === voltage_refrence_type &&
      panel_meter.artificial_voltage[voltage_refrence_type]
    ) {
      return panel_meter.artificial_voltage[voltage_refrence_type];
    }

    return (result['main']['data'] as any)[voltage_ref];
  }

  updatePanelMeter(
    panel: Panel,
    result: PanelCurrentData,
    isLive: boolean = true
  ) {
    if (this.fetchLastMeterDataPanel) return;

    const element = structuredClone(panel);

    const u1 = this.getPanelMeterVoltage('U1', element, result);
    const u2 = this.getPanelMeterVoltage('U2', element, result);
    const u3 = this.getPanelMeterVoltage('U3', element, result);

    element.panel_volts = parseFloat(u1) | parseFloat(u2) | parseFloat(u3) ?? 0;
    element.panel_volts_ref = [u1, u2, u3];
    
    const selector = this.combinePhases
      ? 'panel_circuitsGrouped'
      : 'panel_circuits';

    for (const [key, value] of Object.entries(result)) {
      if (value == null || key == 'main') {
        if (key == 'main') {
          element.panelIsconnected = true;
        }
        continue
      }
      this.setExpansion(value, element, isLive);
    }

    if (
      element.load_balancing === null ||
      element.load_balancing === 'mains_balancing'
    ) {
      element.panel_current =
        parseFloat((result['main']['data'] as any)['I1']) *
          ((isLive && element.panel_meter?.current_ct_clamp?.ct_coefficient) ||
            1) +
          parseFloat((result['main']['data'] as any)['I2']) *
            ((isLive &&
              element.panel_meter?.current_ct_clamp2?.ct_coefficient) ||
              1) +
          parseFloat((result['main']['data'] as any)['I3']) *
            ((isLive &&
              element.panel_meter?.current_ct_clamp3?.ct_coefficient) ||
              1) ?? 0;
    } else {
      element.panel_current = element[selector].reduce(
        (acc: number, curr: any) => {
          acc += curr.circuit_current ?? 0;
          return acc;
        },
        0
      );
    }

    element.panel_power = element.panel_current * element.panel_volts;
    element.panel_cost = element.panel_power * this.costPerKwh;

    const total_current = this.panels.reduce((acc, curr) => {
      if (curr.meter_name === element.meter_name) {
        acc += element.panel_current ?? 0;
      } else {
        acc += curr.panel_current ?? 0;
      }

      return acc;
    }, 0);

    const total_power = this.panels.reduce((acc, curr) => {
      if (curr.meter_name === element.meter_name) {
        acc += element.panel_power ?? 0;
      } else {
        acc += curr.panel_power ?? 0;
      }

      return acc;
    }, 0);

    const total_cost = this.panels.reduce((acc, curr) => {
      if (curr.meter_name === element.meter_name) {
        acc += element.panel_cost ?? 0;
      } else {
        acc += curr.panel_cost ?? 0;
      }

      return acc;
    }, 0);

    if (element.secondary_panel) {
      const secondary_panel = this.panels.find(
        d => d.panel_id === element.secondary_panel
      );

      if (secondary_panel) {
        element.panel_current += secondary_panel.panel_current ?? 0;
        element.panel_power += secondary_panel.panel_power ?? 0;
        element.panel_cost += secondary_panel.panel_cost ?? 0;
      }
    }

    element.panelCurrentPercentage =
      (100 * element.panel_current) / total_current;
    element.panelPowerPercentage = (100 * element.panel_power) / total_power;
    element.panelCostPercentage = (100 * element.panel_cost) / total_cost;

    this.updateCircuitCategories();
    this.sortPanelCircuits(element);

    const idx = this.panels.findIndex(d => d.meter_name === panel.meter_name);
    this.panels[idx] = element;

    this.sortAllPanels();

    this.totalCurrent = total_current;
    this.totalPower = total_power;
    this.totalCost = total_cost;
    this.fetchsetInterval = true;

    this.updateSidebarValues();

    this.panelsSubject.next(this.panels);
    this.circuitCatergoryMainSubject.next(this.circuitCategoryMain);
  }

  handleMqttClientMessage(currentTopic: string, result: PanelCurrentResult) {
    const panel = this.panels.filter(d => d.meter_name === result.ID)[0];
    const raw_data = structuredClone(result.data);

    const { panel_meter } = panel;

    if (panel_meter) {
      const { current_coefficients } = panel_meter;

      for (const x in current_coefficients) {
        result.data[x] = result.data[x] * current_coefficients[x];
      }
    }

    this.panelCurrent[result.ID] = {
      ...(this.panelCurrent[result.ID] ?? {}),
      [result.type]: {
        expansion_id: result.type,
        meter_name: result.ID,
        data: result.data,
        raw_data
      }
    };

    if (result.type === 'main') {
      this.updatePanelMeter(panel, this.panelCurrent[result.ID]);
      delete this.panelCurrent[result.ID];
    }
  }

  connectToMqtt() {
    return new Promise((resolve, reject) => {
      if (this.mqttClient) resolve('Connection already established');

      try {
        const options: mqtt.IClientOptions = {
          protocol: 'wss',
          username: 'sustain_dev',
          password: 'sustain_dev',
          clean: true,
          port: 15676,
          keepalive: 60,
          hostname: 'dev.opti-mized.com',
          path: '/ws',
          clientId: this.currentBuilding.company_id
        };

        if (window.location.protocol.includes('https')) {
          options.protocol = 'wss';
          options.port = 15676;
        }

        if (
          window.location.hostname.includes('facility.opti-mized.com') &&
          !window.location.hostname.includes('dev-')
        ) {
          options.hostname = 'live.opti-mized.com';
          options.username = 'iotdevice1';
          options.password = 'iotdevice1';
          delete options.path;
        }

        const client = mqtt.connect(options);

        this.mqttClient = client;

        client.on('connect', function () {
          console.log('Connected');
          resolve('Connected');
        });

        client.on('message', (currentTopic: string, message: Buffer) => {
          const result = JSON.parse(message.toString()) as PanelCurrentResult;
          this.handleMqttClientMessage(currentTopic, result);
        });

        client.on('error', function (error) {
          console.error('MQTT Connection error: ', error);
          reject(error);
        });

        client.on('disconnect', function () {
          console.log('Disconnected');
        });

        client.on('close', function () {
          console.log('Closed');
        });

        client.on('end', function () {
          console.log('Ended');
        });
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  subscribeToPanelMeters() {
    if (!this.mqttClient) return;

    const non_live_panels = [];

    for (const element of this.panels) {
      const { meter_name, panel_meter } = element;

      if (!meter_name) continue;

      if (panel_meter?.use_api) {
        non_live_panels.push(element);
        continue;
      }

      this.mqttClient.publish(
        `cmd/${meter_name}`,
        JSON.stringify({ name: 'get_nw_data' })
      );

      this.mqttClient.subscribe(`ext1/${meter_name}`, function (err: any) {
        if (err) {
          console.error(err);
        }
      });

      this.mqttClient.subscribe(`ext2/${meter_name}`, function (err: any) {
        if (err) {
          console.error(err);
        }
      });

      this.mqttClient.subscribe(`ext3/${meter_name}`, function (err: any) {
        if (err) {
          console.error(err);
        }
      });

      this.mqttClient.subscribe(`main/${meter_name}`, function (err: any) {
        if (err) {
          console.error(err);
        }
      });
    }

    if (non_live_panels.length > 0) {
      this.subscribeToNotLivePanelMeter(non_live_panels);
    }
  }

  subscribeToNotLivePanelMeter(panels: Panel[]) {
    let count = panels.length;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    for (const panel of panels) {
      const subs = this.dashboardDataService
        .fetchLatestMeterData(panel.meter_name)
        .subscribe(result => {
          count--;
          this.updatePanelMeter(panel, result, false);
          subs.unsubscribe();

          if (count === 0) {
            this.timeout = setTimeout(() => {
              this.subscribeToNotLivePanelMeter(panels);
            }, 1000);
          }
        });
    }
  }

  async getCircuits() {
    for (const element of this.panels) {
      this.isPanelCollapsed.push(true);
      element.panel_expansion = {};

      await this.dashboardDataService
        .getCircuits(element.panel_id)
        .forEach((circuits: any) => {
          element.panel_circuits.push(...circuits.results);

          element.panel_circuitsGrouped.push(
            ...groupCircuitsByCircuitGroup(element.panel_circuits)
          );

          element.panel_circuits.forEach((circuit: Circuit) => {
            circuit.circuit_group_temp =
              circuit.circuit_group == null || circuit.circuit_group == ''
                ? circuit.circuit_name
                : circuit.circuit_group;

            element.panel_circuitsIndexed[circuit.circuit_number] = circuit;

            if (circuit.panel_meter_channels?.length > 0) {
              element.panel_expansion[
                circuit.panel_meter_channels[0].expansion_number
              ] = circuit.circuit_number;
            }
          });

          if (this.secondaryPanelSet.has(element.panel_id)) {
            const primaryPanel = this.panels.find(
              d => d.secondary_panel === element.panel_id
            );

            if (primaryPanel) {
              primaryPanel.panel_circuits.push(...element.panel_circuits);
              primaryPanel.panel_circuitsGrouped.push(
                ...element.panel_circuitsGrouped
              );
            }
          }
        });
    }

    await this.connectToMqtt();
    this.subscribeToPanelMeters();
    // this.getUsageForAll();
  }

  updateCircuitCategories() {
    this.totalAssetCurrent = 0;
    this.totalAssetPower = 0;
    this.totalAssetCost = 0;

    this.circuitCategoryMain.forEach(circuitCategory => {
      this.isInventryCollapsed.push(true);

      let totalCurrentCategory: number = 0;
      let totalPowerCategory: number = 0;
      let totalCostCategory: number = 0;

      for (const circuit_set_element of circuitCategory.circuit_set) {
        if (this.circuitCurrentMapping[circuit_set_element.id]) {
          circuit_set_element['connected'] =
            this.circuitCurrentMapping[circuit_set_element.id].connected;
          circuit_set_element['circuit_disabled'] =
            this.circuitCurrentMapping[circuit_set_element.id].circuit_disabled;
          circuit_set_element['circuit_current'] =
            this.circuitCurrentMapping[circuit_set_element.id].circuit_current;
          circuit_set_element['circuit_power'] =
            this.circuitCurrentMapping[circuit_set_element.id].circuit_power;
          circuit_set_element['circuit_cost'] =
            this.circuitCurrentMapping[circuit_set_element.id].circuit_cost;
          totalCurrentCategory =
            totalCurrentCategory +
            this.circuitCurrentMapping[circuit_set_element.id].circuit_current;
          totalPowerCategory =
            totalPowerCategory +
            this.circuitCurrentMapping[circuit_set_element.id].circuit_power;
          totalCostCategory =
            totalCostCategory +
            this.circuitCurrentMapping[circuit_set_element.id].circuit_cost;
        }

        circuit_set_element['color'] = circuitCategory.colour;
      }

      // sort the circuits based on the current
      circuitCategory.circuit_set.sort(
        (a: { circuit_current: number }, b: { circuit_current: number }) => {
          if (
            a.circuit_current === undefined &&
            b.circuit_current === undefined
          ) {
            return 0;
          } else if (a.circuit_current === undefined) {
            return 1;
          } else if (b.circuit_current === undefined) {
            return -1;
          } else {
            return b.circuit_current - a.circuit_current;
          }
        }
      );

      circuitCategory.circuit_setGroup = groupCircuitsByCircuitGroup(
        circuitCategory.circuit_set
      );

      circuitCategory['totalCurrent'] = totalCurrentCategory;
      circuitCategory['totalPower'] = totalPowerCategory;
      circuitCategory['totalCost'] = totalCostCategory;
      circuitCategory['circuitIsconnected'] = true;

      this.totalAssetCurrent += totalCurrentCategory;
      this.totalAssetPower += totalPowerCategory;
      this.totalAssetCost += totalCostCategory;
    });

    // sort the circuit categories based on the current
    this.circuitCategoryMain.sort((a, b) => {
      if (a.totalCurrent === undefined && b.totalCurrent === undefined) {
        return 0;
      } else if (a.totalCurrent === undefined) {
        return 1;
      } else if (b.totalCurrent === undefined) {
        return -1;
      } else {
        return b.totalCurrent - a.totalCurrent;
      }
    });

    this.getCircuitsMapping();
  }

  sortPanelCircuits(panel: Panel) {
    panel.panel_circuits.sort((a, b) => {
      if (a.circuit_current === undefined && b.circuit_current === undefined) {
        return 0;
      } else if (a.circuit_current === undefined) {
        return 1;
      } else if (b.circuit_current === undefined) {
        return -1;
      } else {
        return b.circuit_current - a.circuit_current;
      }
    });

    panel.panel_circuitsGrouped = groupCircuitsByCircuitGroup(
      panel.panel_circuits
    );
  }

  sortAllPanels() {
    if (this.currentSelection) {
    this.panels.sort((a, b) => {
      if (a.panel_current === undefined && b.panel_current === undefined) {
        return 0;
      } else if (a.panel_current === undefined) {
        return 1;
      } else if (b.panel_current === undefined) {
        return -1;
      } else {
          return b.panel_current - a.panel_current;
        }
      });
    } else if (this.energySelection) {
      this.panels.sort((a, b) => {
        if (a.panel_power === undefined && b.panel_power === undefined) {
          return 0;
        } else if (a.panel_power === undefined) {
          return 1;
        } else if (b.panel_power === undefined) {
          return -1;
        } else {
          return b.panel_power - a.panel_power;
        }
      });
    }
  }

  updateSidebarValues() {
    if (this.sidebarCategoryActive == true) {
      if (this.circuitCategoryMain != null) {
        const series = this.circuitCategoryMain
          .map(circuitCategory =>
            circuitCategory.totalCurrent != null &&
            this.totalAssetCurrent != null
              ? customToFixed(
                  (100 * circuitCategory.totalCurrent) / this.totalAssetCurrent,
                  1,
                  true
                )
              : null
          )
          .filter(ele => !isNaN(ele!) && ele != null)
          .map(ele => parseFloat(ele!.toString()));

        if (series.length > 0) {
          if (!this.chartOptions.initialize) {
            this.chartOptions = {
              series: series,
              dataLabels: { enabled: false },
              legend: { show: false },
              chart: { type: 'donut', width: '320px' },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        label: '',
                        formatter: () => 'Text you want'
                      }
                    }
                  }
                }
              },
              colors: this.circuitCategoryMain.map(
                circuitCategory => circuitCategory.colour
              ),
              labels: this.circuitCategoryMain.map(
                circuitCategory => circuitCategory.name
              ),
              responsive: [
                {
                  breakpoint: 1000,
                  options: {
                    chart: { width: 10 },
                    legend: { position: 'bottom' }
                  }
                }
              ],
              initialize: true,
              stroke: {
                show: false
              }
            };
          }

          this.chartOptions.series = series;
        }
      }
    }

    if (this.sidebarPanelActive == true) {
      const series = this.panels
        .map(panel =>
          panel.panelCurrentPercentage != null &&
          !Number.isNaN(panel.panelCurrentPercentage)
            ? customToFixed(panel.panelCurrentPercentage, 1, true)
            : null
        )
        .filter(ele => !isNaN(ele!) && ele != null)
        .map(ele => parseFloat(ele!.toString()));

      if (series.length > 0) {
        if (!this.chartOptionsPanel.initialize) {
          this.chartOptionsPanel = {
            series: series,
            dataLabels: { enabled: false },
            legend: { show: false },
            chart: { type: 'donut', width: '320px' },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      label: '',
                      formatter: () => 'Text you want'
                    }
                  }
                }
              }
            },
            colors: this.panels.map(panel => panel.colour),
            labels: this.panels.map(panel => panel.panel_name),
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  chart: { width: 10 },
                  legend: { position: 'bottom' }
                }
              }
            ],
            initialize: true,
            stroke: {
              show: false
            }
          };
        }

        this.chartOptionsPanel.series = series;
      }
    }
  }

  getLastMeterData() {
    if (this.fetchLastMeterDataPanel) {
      const element = this.fetchLastMeterDataPanel!;

      const subs = this.dashboardDataService
        .fetchLastMeterData(element.meter_name)
        .subscribe(results => {
          results.forEach((result: any) => {
            let total_current = 0;

            if (Object.keys(result).length === 0) {
              element.fetchsetInterval = false;
              return;
            }

            if (result['main'] != null) {
              element.panel_volts =
                parseFloat(result['main']['data']['U1']) |
                parseFloat(result['main']['data']['U2']) |
                parseFloat(result['main']['data']['U3']);

              const selector = this.combinePhases
                ? 'panel_circuitsGrouped'
                : 'panel_circuits';

              if (
                element.load_balancing === null ||
                element.load_balancing === 'mains_balancing'
              ) {
                element.panel_current_temp =
                  parseFloat(result['main']['data']['I1']) +
                  parseFloat(result['main']['data']['I2']) +
                  parseFloat(result['main']['data']['I3']);
              } else {
                element.panel_current_temp = element[selector].reduce(
                  (acc: number, curr: any) => {
                    acc += curr.circuit_current ?? 0;
                    return acc;
                  },
                  0
                );
              }
            }

            for (const [key, value] of Object.entries(result)) {
              if (value == null || key == 'main') continue;

              total_current =
                total_current + this.setExpansion(value, element, false) || 0;
            }

            // element.panel_current = Number(total_current.toFixed(3));
            this.fetchsetInterval = true;

            this.circuitCategoryMain.forEach(circuitCategory => {
              this.isInventryCollapsed.push(true);
              let totalCurrentCategory: number = 0;
              let totalPowerCategory: number = 0;
              let totalCostCategory: number = 0;
              for (const circuit_set_element of circuitCategory.circuit_set) {
                if (this.circuitCurrentMapping[circuit_set_element.id]) {
                  circuit_set_element['connected'] =
                    this.circuitCurrentMapping[
                      circuit_set_element.id
                    ].connected;
                  circuit_set_element['circuit_disabled'] =
                    this.circuitCurrentMapping[
                      circuit_set_element.id
                    ].circuit_disabled;
                  circuit_set_element['circuit_current'] =
                    this.circuitCurrentMapping[
                      circuit_set_element.id
                    ].circuit_current;
                  circuit_set_element['circuit_power'] =
                    this.circuitCurrentMapping[
                      circuit_set_element.id
                    ].circuit_power;
                  circuit_set_element['circuit_cost'] =
                    this.circuitCurrentMapping[
                      circuit_set_element.id
                    ].circuit_cost;
                  totalCurrentCategory =
                    totalCurrentCategory +
                    this.circuitCurrentMapping[circuit_set_element.id]
                      .circuit_current;
                  totalPowerCategory =
                    totalPowerCategory +
                    this.circuitCurrentMapping[circuit_set_element.id]
                      .circuit_power;
                  totalCostCategory =
                    totalCostCategory +
                    this.circuitCurrentMapping[circuit_set_element.id]
                      .circuit_cost;
                }
                circuit_set_element['color'] = circuitCategory.colour;
              }

              // sort the circuits based on the current
              circuitCategory.circuit_set.sort(
                (
                  a: { circuit_current: number },
                  b: { circuit_current: number }
                ) => {
                  if (
                    a.circuit_current === undefined &&
                    b.circuit_current === undefined
                  ) {
                    return 0;
                  } else if (a.circuit_current === undefined) {
                    return 1;
                  } else if (b.circuit_current === undefined) {
                    return -1;
                  } else {
                    return b.circuit_current - a.circuit_current;
                  }
                }
              );
              circuitCategory.circuit_setGroup = groupCircuitsByCircuitGroup(
                circuitCategory.circuit_set
              );
              circuitCategory['totalCurrent'] = totalCurrentCategory;
              circuitCategory['totalPower'] = totalPowerCategory;
              circuitCategory['totalCost'] = totalCostCategory;
              circuitCategory['circuitIsconnected'] = true;
            });

            // sort the circuit categories based on the current
            this.circuitCategoryMain.sort((a, b) => {
              if (
                a.totalCurrent === undefined &&
                b.totalCurrent === undefined
              ) {
                return 0;
              } else if (a.totalCurrent === undefined) {
                return 1;
              } else if (b.totalCurrent === undefined) {
                return -1;
              } else {
                return b.totalCurrent - a.totalCurrent;
              }
            });

            if (element.panel_current != null) {
              element.panel_power = element.panel_current * element.panel_volts;
              element.panel_cost = element.panel_power * this.costPerKwh;

              this.totalCurrent = this.totalCurrent + element.panel_current;
              this.totalPower = this.totalPower + element.panel_power;
              this.totalCost = this.totalCost + element.panel_cost;
            }

            // sort the circuits based on the current, sometimes current is undefined, it should be at last in the list
            if (element.panel_circuits != null) {
              element.panel_circuits.sort((a, b) => {
                if (
                  a.circuit_current === undefined &&
                  b.circuit_current === undefined
                ) {
                  return 0;
                } else if (a.circuit_current === undefined) {
                  return 1;
                } else if (b.circuit_current === undefined) {
                  return -1;
                } else {
                  return b.circuit_current - a.circuit_current;
                }
              });

              element.panel_circuitsGrouped = groupCircuitsByCircuitGroup(
                element.panel_circuits
              );
            }

            this.panels.sort((a, b) => {
              if (
                a.panel_current === undefined &&
                b.panel_current === undefined
              ) {
                return 0;
              } else if (a.panel_current === undefined) {
                return 1;
              } else if (b.panel_current === undefined) {
                return -1;
              } else {
                return b.panel_current - a.panel_current;
              }
            });

            this.panelsSubject.next(this.panels);
            this.circuitCatergoryMainSubject.next(this.circuitCategoryMain);
          });

          subs.unsubscribe();
          this.fetchLastMeterDataPanel = null;
        });
    }
  }

  setExpansion(expansion: any, element: Panel, isLive: boolean = true) {
    let tic;
    let total_current = 0;

    if (expansion['expansion_id'] == 'ext1') {
      tic = 'A-';
    } else if (expansion['expansion_id'] == 'ext2') {
      tic = 'B-';
    } else if (expansion['expansion_id'] == 'ext3') {
      tic = 'C-';
    } else if (expansion['expansion_id'] == 'ext4') {
      tic = 'D-';
    } else {
      tic = '';
    }

    for (let k = 0; k < 16; k++) {
      const currentId = `I${k + 1}`;
      const expansionId = tic + (k + 1);
      let currentValue = expansion['data'][currentId];

      if (element.panel_expansion[expansionId]) {
        const circuit: Circuit =
          element.panel_circuitsIndexed[element.panel_expansion[expansionId]];

        if (!this.fetchLastMeterDataPanel && isLive) {
          const ct_coefficient = circuit.panel_meter_channels.find(
            d => d.expansion_number === expansionId
          )?.ct_coefficient;

          if (ct_coefficient) {
            currentValue *= ct_coefficient;
          }
        }

        if (circuit != null) {
          if (element.panel_id !== circuit.panel_id) {
            continue;
          }

          circuit.circuit_current = parseFloat(currentValue);
          circuit.connected = true;
          circuit.circuit_disabled = false;
          circuit.circuit_percentage = '0%';
          circuit.circuit_power = circuit.circuit_current * element.panel_volts;
          circuit.circuit_cost = circuit.circuit_power * this.costPerKwh;
          this.circuitCurrentMapping[circuit.id] = circuit;
        }

        total_current = total_current + parseFloat(currentValue);
      }
    }

    element.panelIsconnected = true;
    return total_current;
  }

  getCircuitsMapping() {
    let totalCircuits = this.circuitCategoryMain.reduce((acc, curr) => {
      acc.push(...curr.circuit_set);
      return acc;
    }, []);

    if (this.combinePhases) {
      totalCircuits = groupCircuitsByCircuitGroup(totalCircuits);
    }

    this.circuits = totalCircuits.sort(
      (a: { circuit_current: number }, b: { circuit_current: number }) => {
        if (
          a.circuit_current === undefined &&
          b.circuit_current === undefined
        ) {
          return 0;
        } else if (a.circuit_current === undefined) {
          return 1;
        } else if (b.circuit_current === undefined) {
          return -1;
        } else {
          return b.circuit_current - a.circuit_current;
        }
      }
    );
  }

  radioMainEdit(event: any) {
    if (event.target.id == 'radioPanelCurrent') {
      (
        document.getElementById('radioPanelCurrent') as HTMLInputElement
      ).checked = true;
      (
        document.getElementById('radioPanelEnergy') as HTMLInputElement
      ).checked = false;
      // (document.getElementById('radioPanelCost') as HTMLInputElement).checked =
      //   false;
      this.currentSelection = true;
      this.energySelection = false;
      this.costSelection = false;
      // document.getElementById('panel_current')?.innerHTML = "";
    } else if (event.target.id == 'radioPanelEnergy') {
      (
        document.getElementById('radioPanelCurrent') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelEnergy') as HTMLInputElement
      ).checked = true;
      // (document.getElementById('radioPanelCost') as HTMLInputElement).checked =
      //   false;
      this.currentSelection = false;
      this.energySelection = true;
      this.costSelection = false;
    }
    // else if (event.target.id == 'radioPanelCost') {
    //   (
    //     document.getElementById('radioPanelCurrent') as HTMLInputElement
    //   ).checked = false;
    //   (
    //     document.getElementById('radioPanelEnergy') as HTMLInputElement
    //   ).checked = false;
    //   (document.getElementById('radioPanelCost') as HTMLInputElement).checked =
    //     true;
    //   this.currentSelection = false;
    //   this.energySelection = false;
    //   this.costSelection = true;
    // }
  }

  sidetab(type: string) {
    if (type == 'category') {
      this.sidebarCategoryActive = true;
      this.sidebarPanelActive = false;
    } else {
      this.sidebarPanelActive = true;
      this.sidebarCategoryActive = false;
    }
  }

  checkValue(event: any) {
    var targetedValue = event.target.value;

    // When unchecked
    if (!event.target.checked) {
      var index = 0;
      this.circuitCategoryMain.forEach(element => {
        if (element.name == targetedValue) {
          index = this.circuitCategoryMain.indexOf(element);
        }
      });
      if (index !== -1) {
        let obj = this.circuitCategoryMain.splice(index, 1);
        this.stack.push(obj[0]);
      }
    }

    // When checked
    if (event.target.checked) {
      var index = 0;
      this.stack.forEach(element => {
        if (element.name == targetedValue) {
          index = this.stack.indexOf(element);
        }
      });
      if (index !== -1) {
        let obj = this.stack.splice(index, 1);
        this.circuitCategoryMain.push(obj[0]);
      }
    }
  }

  toggleCombinedPhases(event: any) {
    this.combinePhases = event.target.checked;
    this.getCircuitsMapping();
  }

  // toggleDropdown(index: number): void{
  //     this.isCollapsed[index] = !this.isCollapsed[index]
  // }

  createMeterModal() {
    let dialogRef = this.dialog.open(PanelMeterCreateComponent, {
      data: {
        panels: this.panels
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPanels(this.currentBuilding.idbuildings);
      this.getCircuitCategory('');
      this.fetchLiveAPI = true;
      this.fetchsetInterval = true;
    });
  }

  handleOpenCircuitModel(circuit: Circuit) {
    this.openCircuitModal(circuit);
  }

  isValuePresent(val: any) {
    return val !== null && val !== undefined;
  }
}
